<template>
  <section class="custom-section main-banner">
    <div class="--wrapper">
      <div class="bg-img">
        <img
          src="~/assets/img/main-slider/bg.webp"
          alt="bg"
        >
      </div>
      <div class="container">
        <div class="content ">
          <div class="left">
            <h1 class="title">{{ $t('banner.title') }}</h1>
            <span class="text">{{ $t('banner.text') }}</span>
            <nuxt-link
              :to="localePath(linkForAuth)"
              class="left__button-mobile"
            >
              {{ $t('button.start') }}
            </nuxt-link>
          </div>
          <div
            class="right"
          >
            <BuyNumber id="getSms" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {useUserAuth} from "~/composables/user/useUserAuth";
const { $toast, $api, $ym } = useNuxtApp();
const BuyNumber = defineAsyncComponent(() => import('~/components/home/buy-number/BuyNumber.vue'));

const localePath = useLocalePath();
const { isLoggedIn } = useUserAuth();
const linkForAuth = computed(() => {
  return isLoggedIn ? '/cabinet' : '/authorization';
});
</script>

<style scoped lang="scss">
.main-banner {
	& .--wrapper {
		position: relative;
	}

	& .bg-img {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;

    & > img {
			width: 100%;
      min-height: 500px;
		}
	}

	& .container {
		padding-top: 60px;
	}

	& .content {
		margin-top: 50px;
		display: flex;
		gap: 60px;

		& .left {
			width: 60%;

			& .text {
				display: flex;
				max-width: 70%;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 170%;
			}
		}
    & .left__button-mobile {
        display: block;
        width: fit-content;
        margin: 34px 0;
        padding: 14px 40px;
        border-radius: 14px;
        font-size: 27px;
        font-weight: 700;
        line-height: 131%;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(24, 144, 255, 0.63);
        box-shadow: 0 4 4 0 rgba(26, 56, 116, 0.1);
        transition: var(--transition);
      }
    & .left__button-mobile:hover {
      opacity: 0.8;
    }

		& .right {
			width: 40%;
		}

		& h1 {
			font-family: Roboto, sans-serif;
			color: var(--black);
			font-size: 70px;
			font-style: normal;
			font-weight: 700;
			line-height: 110%;
			margin-bottom: 60px;
		}
	}
	@media screen and (max-width: 1024px) {
    & .content {
      margin-top: 50px;
      display: flex;
      gap: 60px;

      & .left {
        width: 40%;

        & .text {
          display: flex;
          max-width: 70%;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
        }
      }
      //& .left__button-mobile {
      //    display: none;
      //  }

      & .right {
        width: 60%;
      }

      & h1 {
        font-family: Roboto, sans-serif;
        color: var(--black);
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        margin-bottom: 60px;
      }
    }
	}

	@media screen and (max-width: 991px) {
		& .container {
			padding-top: 50px;

    & .left__button-mobile {
        display: block;
        margin: 88px auto 0;
        padding: 14px 40px;
        border-radius: 14px;
        font-size: 27px;
        font-weight: 700;
        line-height: 131%;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(24, 144, 255, 0.63);
        box-shadow: 0 4 4 0 rgba(26, 56, 116, 0.1);
      }
		}

		& .content {
      flex-direction: column;
			margin: 10px 0 0;
			gap: 25px;

			& .left {
				width: 100%;
        min-width: 200px;

				& .text {
          max-width: 100%;
					display: flex;
					font-size: 18px;
					line-height: 20px;
					font-weight: 400;
				}
			}

      & .right {
			  width: 100%;
		  }

			& h1 {
				max-width: 100%;
				line-height: 56px;
				letter-spacing: -3px;
				font-size: 40px;
				margin-bottom: 35px;
			}
		}
	}

  @media screen and (max-width: 640px) {
    & .content {
        h1 {
        font-size: 35px;
        line-height: 50px;
      }
    }
  }
}
</style>
